import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import RegisterOnlineAccount from "../components/members/registerOnlineAccount"
const RegisterOnlineAccountPage = () => {
  return (
    <Layout>
      <SEO
        title="Online Account Registration | Kentucky Tamil Sangam"
        desc="Online Account Registration | Kentucky Tamil Sangam"
        keywords="Online Account Registration | Kentucky Tamil Sangam"
      />
      <RegisterOnlineAccount />
    </Layout>
  )
}

export default RegisterOnlineAccountPage
