import React, { Component } from "react"
import axios from "axios"
import { Link } from "gatsby"
import { Form } from "reactstrap"
import apiRequest from "../../hooks/HttpRequest"
import { navigate } from "gatsby"
import { saveUserOnSessionStorage, isLoggedIn } from "../../services/auth"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default class RegisterOnlineAccount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            membersArray: [],
            MemberID: "",
            FName: "",
            LName: "",
            MobileNo: "",
            PrimaryEmail: "",
            SecondaryEmail: "",
            Password: "",
            PrimaryPhone: "",
            SecondaryPhone: "",
            SpouseName: "",
            SpouseEmail: "",
            FamilyMemberCount: 0,
            MembershipType: "",
            IsOnlineAccount: false,

        }
    }
    insertMember = (e) => {
        e.preventDefault()
        const formData = new FormData()
        formData.append("MemberID", this.state.MemberID)
        formData.append("FName", this.state.FName)
        formData.append("LName", this.state.LName)
        formData.append("PrimaryPhone", this.state.PrimaryPhone)
        formData.append("SecondaryPhone", '')
        formData.append("SecondaryEmail", '')
        formData.append("PrimaryEmail", this.state.PrimaryEmail)
        formData.append("SpouseName", '')
        formData.append("SpouseEmail", '')
        formData.append("FamilyMemberCount", 0)
        formData.append("Password", this.state.Password)
        formData.append("MembershipType", '')
        formData.append("MembershipFees", '')
        formData.append("IsOnlineAccount", 1)
        formData.append("IsAdmin", false)
        formData.append("IsCommitteeMember", false)
        formData.append("PhotoURL", "")
        formData.append("CreatedBy", 'onlineaccount')
        formData.append("UpdatedBy", '')

        // // Display the key/value pairs
        // for (var pair of formData.entries()) {
        //  // console.log(pair[0] + ', ' + pair[1]);
        // }
        // return;

        //OnlineAccount Flag supplied in place of CreatedBy
        return axios
            .put(
                apiRequest.baseURL + "/Members/UpdateMemberOnlineAccount/" + this.state.MemberID,
                formData,
                apiRequest.multipartFormHeader
            )
            .then((response) => {

                if (response.data.Status === "Success") {
                    saveUserOnSessionStorage(response.data.User)
                    toast.success("Online Account will be Created! Contact KyTS Admin for Activation!");
                    this.setState({
                        MemberID: 0,
                        FName: "",
                        LName: "",
                        MobileNo: "",
                        PrimayEmail: "",
                        SecondaryEmail: "",
                        Password: "",
                        PrimaryPhone: "",
                        SecondaryPhone: "",
                        SpouseName: "",
                        SpouseEmail: "",
                        FamilyMemberCount: 0,
                        MembershipType: "",
                        IsOnlineAccount: false,
                    }, () => {
                        navigate(`/members/profile`)
                    })
                }
                else {

                    if (response.data.Message.indexOf("UNIQUE KEY") > -1) {
                        toast.error("Primary Email already Exists!");
                    }
                    if (response.data.Message.indexOf("No Member Record Found!") > -1) {
                        // console.log("Error!!!");
                        // console.log(response.data.Message);
                        toast.error("Credentials not Mathching!. Contact KyTS Admin!");
                    }
                    else {
                        toast.error(response.data.Message)
                    }
                }
            })
            .catch((e) => {
                // console.log(e)
            })
    }


    //handles form inputs change events
    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    render() {
        if (isLoggedIn()) {
            navigate(`/members/profile`)
        }
        return (
            <>
                <div className="page-title-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h2>Register Online Account</h2>
                                    <ul>
                                        <li>
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li>Register Online Account</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="signup-section ptb-100">
                    <div className="container">
                        <div className="signup-form">
                            <h3>Register Online Account</h3>
                            <p>Please furnish below details</p>
                            <Form onSubmit={this.insertMember}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                name="MemberID"
                                                required
                                                type="text"
                                                className="form-control"
                                                placeholder="Member ID"
                                                onChange={this.handleChange}
                                                value={this.state.MemberID}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        &nbsp;
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                name="FName"
                                                required
                                                type="text"
                                                className="form-control"
                                                placeholder="First Name"
                                                onChange={this.handleChange}
                                                value={this.state.FName}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                type="text"
                                                name="LName"
                                                className="form-control"
                                                placeholder="Last Name"
                                                value={this.state.LName}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                placeholder="Primary Email (User Name)"
                                                required
                                                type="email"
                                                name="PrimaryEmail"
                                                className="form-control"
                                                value={this.state.PrimaryEmail}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input
                                                autoComplete="off"
                                                placeholder="Primary Phone"
                                                required
                                                type="tel"
                                                name="PrimaryPhone"
                                                className="form-control"
                                                value={this.state.PrimaryPhone}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6" >
                                        <div className="form-group">
                                            <input
                                                placeholder="Password"
                                                required
                                                type="password"
                                                name="Password"
                                                className="form-control"
                                                value={this.state.Password}
                                                onChange={this.handleChange}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="send-btn">
                                            <button onClick={this.checkLogin} className="default-btn">
                                                Create Online Account
                                                <span />
                                            </button>
                                        </div>
                                        <br />
                                        <span>
                                            Already a registered user?{" "}
                                            <Link to="/login">Login!</Link>
                                        </span>
                                        <ToastContainer />
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
